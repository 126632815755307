import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paypal-logo',
  templateUrl: './paypal-logo.component.html',
  styleUrls: ['./paypal-logo.component.scss']
})
export class PayPalLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
