import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twoC2P-logo',
  templateUrl: './twoC2P-logo.component.html',
  styleUrls: ['./twoC2P-logo.component.scss']
})
export class TwoC2PLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
