import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'payments-ui';

  constructor(private activatedRouteSnapshot: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {
    this.activatedRouteSnapshot.url.subscribe((urlObserver) => {
      // if (urlObserver.toString() === '') {
      //   this.router.navigate(['/choosePlan']);
      // }
    });

  }
}
