import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: '.\/modules\/payment-chooser\/payment-chooser.module#PaymentChooserModule'
  },
  {
    path: 'paytm',
    loadChildren: '.\/modules\/paytm-module\/paytm-module.module#PaytmModule'
  },
  {
    path: 'razorpay',
    loadChildren: '.\/modules\/razorpay-module\/razorpay-module.module#RazorpayModule'
  },
  {
    path: '2c2p',
    loadChildren: '.\/modules\/2c2p-module\/twoC2P-module.module#TwoC2PModuleModule'
  },
  {
    path: 'stripe',
    loadChildren: '.\/modules\/stripe-module\/stripe-module.module#StripeModuleModule'
  },
  {
    path: 'paypal',
    loadChildren: '.\/modules\/paypal-module\/paypal-module.module#PaypalModuleModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
