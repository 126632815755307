import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderComponent } from './components/header/header.component';
import { PayPalLogoComponent } from './components/logos/paypal-logo/paypal-logo.component';
import { PaytmLogoComponent } from './components/logos/paytm-logo/paytm-logo.component';
import { RazorpayLogoComponent } from './components/logos/razorpay-logo/razorpay-logo.component';
import { StripeLogoComponent } from './components/logos/stripe-logo/stripe-logo.component';
import { TwoC2PLogoComponent } from './components/logos/twoC2P-logo/twoC2P-logo.component';

@NgModule({
  declarations: [HeaderComponent, PaytmLogoComponent, RazorpayLogoComponent, TwoC2PLogoComponent, StripeLogoComponent, PayPalLogoComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule
  ],
  exports: [HeaderComponent, PaytmLogoComponent, RazorpayLogoComponent, TwoC2PLogoComponent, StripeLogoComponent, PayPalLogoComponent]
})
export class SharedModule { }
